<template>
  <section class="w-full h-screen">
    <div id="content" class="w-11/12 md:w-1/3 m-auto mt-12">
      <img class="" src="@/assets/security-icon.svg" alt="Security icon" />
      <h2 class="text-center text-white">MULTI-FACTOR AUTHENTICATION</h2>
      <p class="text-center text-white mt-2">
        Open the two-factor authenticator (TOTP) app on your mobile device to
        view your authentication code. Some compatible apps are Google
        Authenticator and 2FA Authenticator.
      </p>
      <OTPForm @submitEvent="handleSubmit" />
      <div v-if="showErrorMessage" class="mt-8 text-red-500 text-center">
        The TOTP code is incorrect. please try again or try login with a
        recovery code.
      </div>
      <div class="mt-12">
        <p class="text-white text-center">
          Having problems? |
          <router-link class="text-white" :to="{ name: 'Recovery' }">
            Use a recovery code
          </router-link>
        </p>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import OTPForm from "@/components/auth/OTPForm.vue";

const store = useStore();
const router = useRouter();
const showErrorMessage = ref(false);

const submitTOTPCode = async (totpCode) =>
  store.dispatch("user/verifyTOTP", { totpCode });

async function handleSubmit(code) {
  showErrorMessage.value = false;
  const response = await submitTOTPCode(code);

  if (response) {
    return router.push({ name: "Home" });
  }

  showErrorMessage.value = true;
}
</script>

<style scoped>
img {
  display: block;
  margin: 2rem auto;
  height: 40px;
  width: 40px;
}
.text-color {
  color: #1d2e48;
}

@media (min-width: 1400px) {
  img {
    height: 60px;
    width: 60px;
  }
}
</style>
